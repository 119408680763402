import React, { useEffect, useState } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isScrolled, setIsScrolled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > window.innerHeight * 0.1) { 
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

  }, []); 

  return (
    <div className={`container ${isScrolled ? 'transparent' : ''}`}>
      <div className="background-blur"></div>
      <div className="content">
        <img
          src={`${process.env.PUBLIC_URL}/icons/ci-dev.svg`}
          alt="Ci-Dev Logo"
          style={{
            cursor: "pointer"
          }}
          width={400}
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        />
        <button
          className="cta-btn"
          style={{cursor: "pointer"}}
          onClick={() =>
            window.open(
              "https://calendly.com/ci-dev/kickoff-ci-development?month=2024-09",
              "_blank"
            )
          }
        >
          Kostenfreie Beratung sichern
        </button>
      </div>
    </div>
  );
}

export default Header;
