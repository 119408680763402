import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./Components/Header/Header";
import KiPage from "./Pages/KiPage/KiPage";
import Navbar from "./Components/Navbar/Navbar";
import HpcPage from "./Pages/HPC-Page/HpcPage";
import IotPage from "./Pages/Iot-Page/IotPage";
import CloudEngineering from "./Pages/Cloud-Engineering/CloudEngineering";
import SoftwareDevelopment from "./Pages/Software-Development/SoftwareDevelopment";
import Datenschutz from "./Pages/Datenschutz/Datenschutz";
import Impressum from "./Pages/Impressum/Impressum";
import NewHeader from "./Components/Header/NewHeader";

const Layout = () => {
  const location = useLocation();

  const hideNavbar =
    location.pathname === "/datenschutz" || location.pathname === "/impressum";

  return (
    <div>
      <NewHeader />
      {!hideNavbar && <Navbar />}
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Layout />
      <Routes>
        <Route path="/" element={<KiPage />} />
        <Route path="/künstliche-intelligenz" element={<KiPage />} />
        <Route path="/hpc" element={<HpcPage />} />
        <Route path="/iot" element={<IotPage />} />
        <Route path="/cloud-engineering" element={<CloudEngineering />} />
        <Route path="/software-development" element={<SoftwareDevelopment />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
