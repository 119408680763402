import React from "react";
import "../Cloud-Engineering/cloudengineering.css";
import { useNavigate } from "react-router-dom";

const SoftwarDevelopment = () => {
  const navigate = useNavigate();
  return (
    <div className="kicontainer">
      <div className="videosection">
        <img
          className="videocard"
          style={{ borderRadius: "3vh" }}
          src={`${process.env.PUBLIC_URL}/images/softwaredevelopment_initial.png`}
          alt="Carsten Halmen"
        />
      </div>
      <div className="header-newsection-container">
        <div className="headercard">
          <h1 className="heading-ki">
            Ihre Vision, unsere Expertise <br />
            <span className="highlight">
              Entwicklung von Softwarelösungen, die funktionieren
            </span>
          </h1>
          <li>
            Vermeidung von aufwendigen Softwareanpassungen durch ein
            Expertenteam mit KnowHow in Softwareplanung, -entwicklung und
            -betrieb.
          </li>
          <li>
            Schnelle und effiziente Realisierung Ihrer Software durch ein direkt
            nutzbares Entwicklungsumfeld und erfahrenen Experten
          </li>
          <button
            className="readmore-button"
            onClick={() => {
              const element = document.getElementById("solutions");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Mehr erfahren
            <div className="dropdown-icon">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/dropdownicon.svg`}
              />
            </div>
          </button>
        </div>
        <div className="newsection" id="solutions">
          Für Gründer und Projektleiter die eine Software-Idee zu einem
          marktreifen Produkt bringen wollen.
        </div>
      </div>
      <div
        className="solutions"
        style={{ width: "90vw", display: "grid", gap: "2vw" }}
      >
        <div className="full-width" style={{ color: "rgba(163,54,151,255)" }}>
          Unsere Lösung
          <span style={{ color: "rgba(122,110,192,255)" }}> für Sie</span>
        </div>
        <div className="solutionleft" style={{ marginLeft: "10vw" }}>
          <div
            className="solutionCard"
            style={{
              flex: 1,
              gridTemplateColumns: "1fr 4fr",
            }}
          >
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/developmentcode.svg`}
                alt="software development icon"
              />
            </div>
            <div>
              <h3>
                Verschiedene <br /> Programmiersprachen
              </h3>
              <p>
                Wir unterstützen eine Vielzahl von Programmier-sprachen,
                darunter C++, Python, Go und JavaScript. Unser Team ist flexibel
                und kann sich an Ihre spezifischen Anforderungen anpassen.
              </p>
            </div>
          </div>
        </div>
        <div className="solutionsright">
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "4fr 1fr" }}
          >
            <div>
              <h3>
                Schnelle und effiziente <br /> Softwareentwicklung{" "}
              </h3>
              <p>
                Wir bieten eine vollständige CI/CD-Pipeline an, die die gesamte
                Kette von Planungstools bis zur Softwarerollout abdeckt. Unsere
                Pipeline gewährleistet eine schnelle und zuverlässige
                Softwareentwicklung.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/rocket.svg`}
                alt="frame icon"
              />
            </div>
          </div>
          <div className="solution-central" style={{ display: "none" }}>
            <div className="solution-little-card">
              Sie haben rechtliche Bedenken oder Fragen zur Nutzung von KI?
              Unser Systempartner DDSK ist spezialisiert und hilft Ihnen gerne
              weiter.
              <div>
                <button
                  onClick={() =>
                    window.open("https://www.ddsk.gmbh/ai", "_blank")
                  }
                  style={{
                    marginTop: "0vh",
                    marginLeft: "3vw",
                    width: "12vw",
                    padding: "1vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="readmore-button"
                >
                  Die DDSK kennenlernen{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="process"
        style={{ backgroundColor: "white", width: "100%", height: "100vh" }}
      >
        <div className="text">Der Prozess, der Sie erwartet</div>
        <div>
          <img
            style={{ width: "50vw", height: "90vh" }}
            src={`${process.env.PUBLIC_URL}/images/software-development-process-img.svg`}
            alt="process"
          />
        </div>
      </div>
      <div className="more">
        <p>Weitere Leisungen</p>
        <div className="more-container">
          <div
            onClick={() => {
              navigate("/cloud-engineering");
              window.location.reload();
            }}
          >
            Cloud <br /> Engineering
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/cloud.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/ki");
              window.location.reload();
            }}
          >
            Künstliche
            <br />
            Intelligenz
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/ki.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/iot");
              window.location.reload();
            }}
          >
            Internet of <br /> things
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/iot.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/hpc");
              window.location.reload();
            }}
          >
            High Performance <br /> Computing
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/thunder.svg`}
              alt="PC-Icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwarDevelopment;
