import React from "react";
// import "./cloudengineering.css";
import { useNavigate } from "react-router-dom";

const IotPage = () => {
  const navigate = useNavigate();
  return (
    <div className="kicontainer">
      <div className="videosection">
        <img
          className="videocard"
          style={{ borderRadius: "3vh" }}
          src={`${process.env.PUBLIC_URL}/images/iot_initial.png`}
          alt="Carsten Halmen"
        />
      </div>
      <div className="header-newsection-container">
        <div className="headercard">
          <h1 className="heading-ki">
            Die Zukunft Ihrer Daten Von der Idee bis zur Implementierung Ihres{" "}
            <br />
            <span className="highlight">Internet of Things</span>
          </h1>
          <li>
            Geringer Abstimmungsbedarf zwischen Software-, Cloud- und
            Hardwareexperten
          </li>
          <li>
            Potenzielle Erweiterung neuer Produktfeatures durch KI- und
            Bildverarbeitungsexpertise
          </li>
          <li>Schnelle Evaluation durch 3D-gedruckte Test-Prototypen</li>
          <button
            className="readmore-button"
            onClick={() => {
              const element = document.getElementById("solutions");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Mehr erfahren
            <div className="dropdown-icon">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/dropdownicon.svg`}
              />
            </div>
          </button>
        </div>
        <div className="newsection" id="solutions">
          Für Gründer und Projektleiter, die ihre Softwarelösung um IoT-Hardware
          wie Kameras oder Sensoren erweitern wollen, ohne eigene
          Hardware-Expertise.
        </div>
      </div>
      <div className="solutions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "1fr 4fr", width: "35vw" }}
          >
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/computer.svg`}
                alt="location icon"
              />
            </div>
            <div>
              <h3>Planung, Auslegung und Entwicklung</h3>
              <p>
                Unser Expertenteam übernimmt die Planung, die Auslegung und die
                Entwicklung von Soft- und Hardware, um Ihnen eine
                maßgeschneiderte IoT-Lösung zu liefern, die Ihren Bedürfnissen
                gerecht wird.
              </p>
            </div>
          </div>
          <div
            className="solutionCard"
            style={{
              gridTemplateColumns: "4fr 1fr",
              width: "35vw",
              marginLeft: "40vw",
            }}
          >
            <div>
              <h3>Entwicklung von Prototypen</h3>
              <p>
                Wir entwickeln schnell und flexibel Prototypen mittels 3D-Druck,
                die es uns ermöglichen, Ihre Anforderungen genauer zu verstehen,
                potenzielle Probleme frühzeitig zu identifizieren und Ihr
                Produkt weiter zu verbessern.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/multiarrow.svg`}
                alt="protection icon"
              />
            </div>
            {/* </div> */}
          </div>
          {/* <div className="solutionright"> */}
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "4fr 1fr", width: "35vw" }}
          >
            <div>
              <h3>
                Implementierung von Prototypen oder serienreifen Lösungen{" "}
              </h3>
              <p>
                Wir implementieren die IoT-Lösung in die Produktionsumgebung und
                sorgen dafür, dass alle Entwicklungen und Änderungen korrekt
                umgesetzt werden. Durch unsere sorgfältige Arbeit stellen wir
                sicher, dass die Lösung reibungslos arbeitet und alle
                Anforderungen erfüllt.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/miniframe.svg`}
                alt="frame icon"
              />
            </div>
          </div>
          <div className="solution-central" style={{ display: "none" }}>
            <div
              className="solution-little-card"
              style={{ position: "relative", left: "35vw" }}
            >
              Sie haben rechtliche Bedenken oder Fragen zur Nutzung von KI?
              Unser Systempartner DDSK ist spezialisiert und hilft Ihnen gerne
              weiter.
              <div>
                <button
                  onClick={() =>
                    window.open("https://www.ddsk.gmbh/ai", "_blank")
                  }
                  style={{
                    marginTop: "0vh",
                    marginLeft: "3vw",
                    width: "12vw",
                    padding: "1vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="readmore-button"
                >
                  Die DDSK kennenlernen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="process"
        style={{ backgroundColor: "white", width: "100%", height: "100vh" }}
      >
        <div className="text">Der Prozess, der Sie erwartet</div>
        <div>
          <img
            style={{ width: "70vw", height: "100vh" }}
            src={`${process.env.PUBLIC_URL}/images/iot-process-img.svg`}
            alt="process"
          />
        </div>
      </div>
      <div className="more">
        <p>Weitere Leisungen</p>
        <div className="more-container">
          <div
            onClick={() => {
              navigate("/software-development");
              window.location.reload();
            }}
          >
            Software-
            <br />
            entwicklung
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/pc-code.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/cloud-engineering");
              window.location.reload();
            }}
          >
            Cloud <br /> Engineering
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/cloud.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/iot");
              window.location.reload();
            }}
          >
            Internet of <br /> things
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/iot.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/künstliche-intelligenz");
              window.location.reload();
            }}
          >
            Künstliche <br /> Intelligenz
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/ki.svg`}
              alt="PC-Icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IotPage;
