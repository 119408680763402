import React from "react";
import "./cloudengineering.css";
import { useNavigate } from "react-router-dom";

function CloudEngineering() {
  const navigate = useNavigate();
  return (
    <div className="kicontainer">
      <div className="videosection">
        <img
          className="videocard"
          style={{ borderRadius: "3vh" }}
          src={`${process.env.PUBLIC_URL}/images/cloud_initial.png`}
          alt="Carsten Halmen"
        />
      </div>
      <div className="header-newsection-container">
        <div className="headercard">
          <h1 className="heading-ki">
            Von lokal zu global <br />
            <span className="highlight">
              Effiziente Cloud-Lösungen für Ihre Softwareprojekte
            </span>
          </h1>
          <li>In 5 Tagen zu Ihren individuellen Cloudsetting</li>
          <li>Betreute Infrastruktur Pakete zum Festpreis</li>
          <li>Begleitung Ihres Entwicklungsteams beim Software RollOut</li>
          <li>Nach Ausfall automatisierte Datenwiederherstellung</li>
          <li>Kostenoptimiertes Setup für Ihre Cloud-Dienste</li>
          <button
            onClick={() => {
              const element = document.getElementById("solutions");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
            className="readmore-button"
          >
            Mehr erfahren
            <div className="dropdown-icon">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/dropdownicon.svg`}
              />
            </div>
          </button>
        </div>
        <div className="newsection" id="solutions">
          Für Software-Projektleiter in Startup oder Teams, die neue
          Software-projekte etablieren oder in die Cloud erweitern möchten.
        </div>
      </div>
      <div className="solutions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "1fr 3fr", width: "35vw" }}
          >
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/time.svg`}
                alt="location icon"
              />
            </div>
            <div>
              <h3>Sofort loslegen - Ihre Cloud in 5 Tagen</h3>
              <p>
                Erhalten Sie Zugang zu Ihrer eigenen Open Telekom
                Cloud-Infrastruktur in nur 5 Tagen. Unsere Experten helfen Ihnen
                oder übernehmen für Sie die Einrichtung und Konfiguration, damit
                Sie sofort produktiv werden können.
              </p>
            </div>
          </div>
          <div
            className="solutionCard"
            style={{
              marginLeft: "40vw",
              gridTemplateColumns: "3fr 1fr",
              width: "35vw",
            }}
          >
            <div>
              <h3>Erfolgsrezepte für Ihre Cloud-Projekte</h3>
              <p>
                Nutzen Sie unsere erprobten Konzepte, um Ihre Cloud-Projekte
                schnell und erfolgreich zu starten. Unsere Experten haben die
                besten Praktiken zusammengetragen, um Ihnen mit fertig
                konfigurierten Setups den Weg zu erleichtern.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/cloudwithok.svg`}
                alt="protection icon"
              />
            </div>
            {/* </div> */}
          </div>
          {/* <div className="solutionright"> */}
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "3fr 1fr", width: "35vw" }}
          >
            <div>
              <h3>Wir unterstützen Sie von der Planung bis zum Betrieb </h3>
              <p>
                Wir speichern Ihre Daten strikt separiert und verhindern den
                Zugriff durch Dritte, um Ihre sensiblen Informationen zu
                schützen.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/handshake.svg`}
                alt="frame icon"
              />
            </div>
          </div>
          <div className="solution-central" style={{ display: "none" }}>
            <div className="solution-little-card">
              Sie haben rechtliche Bedenken oder Fragen zur Nutzung von KI?
              Unser Systempartner DDSK ist spezialisiert und hilft Ihnen gerne
              weiter.
              <div>
                <button
                  onClick={() =>
                    window.open("https://www.ddsk.gmbh/ai", "_blank")
                  }
                  style={{
                    marginTop: "0vh",
                    marginLeft: "3vw",
                    width: "12vw",
                    padding: "1vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="readmore-button"
                >
                  Die DDSK kennenlernen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="process"
        style={{ backgroundColor: "white", width: "100%", height: "100vh" }}
      >
        <div className="text">Der Prozess, der Sie erwartet</div>
        <div>
          <img
            style={{ width: "70vw", height: "90vh" }}
            src={`${process.env.PUBLIC_URL}/images/cloud-process-img.svg`}
            alt="process"
          />
        </div>
      </div>
      <div className="more">
        <p>Weitere Leisungen</p>
        <div className="more-container">
          <div
            onClick={() => {
              navigate("/software-development");
              window.location.reload();
            }}
          >
            Software-
            <br />
            entwicklung
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/pc-code.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/cloud-engineering");
              window.location.reload();
            }}
          >
            Künstliche <br /> Intelligenz
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/ki.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/künstliche-intelligenz");
              window.location.reload();
            }}
          >
            Internet of <br /> things
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/iot.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/hpc");
              window.location.reload();
            }}
          >
            High Performance <br /> Computing
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/thunder.svg`}
              alt="PC-Icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloudEngineering;
