import React, { useState } from "react";
import "./kipage.css";
import { useNavigate } from "react-router-dom";
import BigCard from "./BigCard";
import Footer from "../../Components/Footer/Footer";

function KiPage() {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  return (
    <div className="kicontainer">
      {clicked ? (
        <div>
          <BigCard />
        </div>
      ) : (
        <>
          <div className="videosection">
            <img
              className="videocard"
              src={`${process.env.PUBLIC_URL}/images/ki_initial.png`}
              alt="Carsten Halmen"
            />
          </div>
          <div className="header-newsection-container">
            <div className="headercard">
              <h1 className={"heading-ki"}>
                Schnelligkeit, Sicherheit und Präzision mit <br />
                <span className="highlight">Künstlicher Intelligenz</span>
              </h1>
              <li>Nahtlose Erweiterung Ihrer aktuellen Geschäftsprozesse</li>
              <li>Individuelle Benutzeroberfläche für Ihre Bedürfnisse</li>
              <li>Datenaufbereitung für erfolgreiche eine KI-Nutzung</li>
              <li>Vermeidung von Wissens- und Datenverlust</li>
              <button
                onClick={() => setClicked(true)}
                className={`${clicked ? "big-card" : "readmore-button"}`}
              >
                Mehr zu KI und KnowHow-Schutz
                <div className="dropdown-icon">
                  <img
                    style={{ width: "1.5vw" }}
                    src={`${process.env.PUBLIC_URL}/icons/dropdownicon.svg`}
                  />
                </div>
              </button>
            </div>
            <div className="newsection">
              Für Gründer und Projektleiter in mittelständischen Unternehmen mit
              Know-How-basierten oder Datenschutz-sensiblen Geschäftsmodellen.
            </div>
          </div>
        </>
      )}
      <div className="solutions">
        <div className="full-width" style={{ color: "rgba(163,54,151,255)" }}>
          Unsere Lösung{" "}
          <span style={{ color: "rgba(122,110,192,255)" }}> für Sie</span>
        </div>
        <div className="solutionleft">
          <div className="solutionCard">
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/location.svg`}
                alt="location icon"
              />
            </div>
            <div>
              <h3>Deutsche, nicht öffentliche KI</h3>
              <p>
                Wir bieten eine nichtöffentliche KI gehostet in Deutschland, um
                Ihre Daten sicher zu halten und die Einhaltung von
                Datenschutzbestimmungen zu gewährleisten.
              </p>
            </div>
          </div>
          <div className="solutionCard">
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/protectionicon.svg`}
                alt="protection icon"
              />
            </div>
            <div>
              <h3>Wissens- und Datenschutz durch separate Datenhaltung</h3>
              <p>
                Wir speichern Ihre Daten strikt separiert und verhindern den
                Zugriff durch Dritte, um Ihre sensiblen Informationen zu
                schützen.
              </p>
            </div>
          </div>
        </div>
        <div className="solutionright">
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "3fr 1fr" }}
          >
            <div>
              <h3>Individuelle KI-Lösungen </h3>
              <p>
                Wir entwickeln und stellen maßgeschneiderte KI-Funktionen in
                einer eigenen Applikation oder als Schnittstelle für Ihre
                Software bereit, um Ihre spezifischen Anforderungen zu erfüllen.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/frameicon.svg`}
                alt="frame icon"
              />
            </div>
          </div>
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "3fr 1fr" }}
          >
            <div>
              <h3>Einbettung in die unternehmens-interne Infrastruktur</h3>
              <p>
                Wir betreiben eine eigene Infrastruktur, um die volle Kontrolle
                über die Datenverarbeitung und -speicherung zu haben und Ihre
                Sicherheitsanforderungen zu erfüllen.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/connection.svg`}
                alt="connection icon"
              />
            </div>
          </div>
          <div className="solution-central">
            <div className="solution-little-card">
              Sie haben rechtliche Bedenken oder Fragen zur Nutzung von KI?
              Unser Systempartner DDSK ist spezialisiert und hilft Ihnen gerne
              weiter.
              <div>
                <button
                  onClick={() =>
                    window.open("https://www.ddsk.gmbh/ai", "_blank")
                  }
                  style={{
                    marginTop: "0vh",
                    marginLeft: "3vw",
                    width: "15vw",
                    padding: "1vw",
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "center",
                  }}
                  className="readmore-button"
                >
                  Die DDSK kennenlernen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="process"
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text">Der Prozess, der Sie erwartet</div>
        <div>
          <img
            style={{ width: "100%", height: "90vh" }}
            src={`${process.env.PUBLIC_URL}/images/ki-process-img.svg`}
            alt="process"
          />
        </div>
      </div>
      <div className="more">
        <p>Weitere Leisungen</p>
        <div className="more-container">
          <div
            onClick={() => {
              navigate("/software-development");
              window.location.reload();
            }}
          >
            Software-
            <br />
            entwicklung
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/pc-code.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/cloud-engineering");
              window.location.reload();
            }}
          >
            Cloud <br /> Engineering
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/cloud.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/iot");
              window.location.reload();
            }}
          >
            Internet of <br /> things
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/iot.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/hpc");
              window.location.reload();
            }}
          >
            High Performance <br /> Computing
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/thunder.svg`}
              alt="PC-Icon"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default KiPage;
