import React from "react";
import "./impressum.css";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const Impressum = () => {
  const navigate = useNavigate();
  return (
    <div className="impressum-container">
      <div className="impressum-card">
        <span
          style={{
            textAlign: "right",
            fontSize: "2vw",
            marginTop: "-13vh",
            marginRight: "1vw",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          {" "}
          &times;
        </span>
        <h2>Impressum</h2>
        <h4>CI-Development GmbH</h4>
        <p>
          Preußenstrasse, 21
          <br />
          66111 Saarbrücken
        </p>
        <div>
          <h3>
            E-Mail:{" "}
            <a style={{ color: "black" }} href="mailto:info@ci-dev.de">
              info@ci-dev.de
            </a>
          </h3>
        </div>
        <div>
          <p>Vertreten durch:</p>
          <div>
            <p>
              Geschäftsführer: <span>Carsten Halmen</span>
            </p>
            <p>
              Registereintrag: <span>Eintragung im Handelsregister</span>
            </p>
            <p>
              Registergericht: <span>Saarbrücken</span>
            </p>
            <p>
              Registernummer: <span>HRB 107098</span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", paddingTop: "3vh" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Impressum;
