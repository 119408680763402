import React, { useState } from "react";
import "./bigcard.css";

const BigCard = () => {
  const [clicked, setClicked] = useState(false);
  const basicUri = `${process.env.PUBLIC_URL}/icons`;

  return (
    <div className="big-card-container">
      <div className="big-card">
        <h1>Der Unterschied zwischen öffentlicher und privater KI</h1>
        <span
          className={"close-icon"}
          onClick={() => {
            setClicked(true);
            window.location.reload();
          }}
        >
          &times;
        </span>
        <div className="table-container">
          <table className="table1">
            <thead>
              <tr className="column-header">
                <th>Thema / Kriterien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  className="row-header"
                  style={{ color: "rgba(52, 104, 204, 0.8)" }}
                >
                  <img
                    src={`${basicUri}/specializing.svg`}
                    alt="specializing icon"
                    style={{ marginRight: "1vw", width: "0.8vw" }}
                  />
                  Individuelle Spezialisierung
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Anpassbare Benutzeroberfläche
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "rgba(173, 216, 230, 0.4)",
                  borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                  lineHeight: "2.5vh",
                }}
              >
                <td>Funktionelle Anpassung</td>
              </tr>
              <tr
                style={{
                  backgroundColor: "rgba(173, 216, 230, 0.4)",
                  borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                  lineHeight: "2.5vh",
                }}
              >
                <td>Integration in bestehende Systeme / Fachsoftware</td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Wunschfunktionen
                </td>
              </tr>
              <tr>
                <th
                  className="row-header"
                  style={{
                    color: "rgba(128, 0, 128, 0.8)",
                    lineHeight: "2.5vh",
                  }}
                >
                  <img
                    src={`${basicUri}/protection.svg`}
                    alt="protection icon"
                    style={{ marginRight: "1vw", width: "0.8vw" }}
                  />
                  Informations- und Wissenschutz
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(128, 0, 128, 0.8)",
                    lineHeight: "2.5vh",
                  }}
                >
                  100%iger Schutz Ihrer Daten
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Möglichkeit zur Löschung gespeicherter Daten
                </td>
              </tr>
              <tr>
                <th
                  className="row-header"
                  style={{
                    color: "rgba(75, 0, 130, 0.8)",

                    lineHeight: "2.5vh",
                  }}
                >
                  <img
                    src={`${basicUri}/book.svg`}
                    alt="book icon"
                    style={{
                      marginRight: "1vw",
                      width: "0.8vw",
                    }}
                  />
                  Wissensbasis
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Zugriff auf öffentlich verfügbare Daten
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Zugriff auf interne Daten
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Zugriff auf externe Wissensquellen
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Zugriff auf interne Wissensquellen
                </td>
              </tr>
              <tr>
                <th
                  className="row-header"
                  style={{
                    color: "rgba(255, 20, 147)",
                    lineHeight: "2.5vh",
                  }}
                >
                  <img
                    src={`${basicUri}/income_outgo.svg`}
                    alt="specializing icon"
                    style={{ marginRight: "1vw" }}
                  />
                  Ein- und Ausgabe
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(255, 20, 147, 1)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Ein- und Ausgabe von Text
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottom: "2px solid rgba(255, 20, 147, 1)",
                    lineHeight: "2.5vh",
                  }}
                >
                  Ein- und Ausgabe von Standarddokumenten
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgba(173, 216, 230, 0.4)",
                    borderBottomLeftRadius: "1vw",
                    lineHeight: "2.5vh",
                  }}
                >
                  Anpassung auf individuelle Formate
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <table className="table2">
              <thead>
                <tr className="column-header">
                  <th>Öffentliche KI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "2.5vh" }}>
                    {" "}
                    ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "-0.9vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Ja</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/cancel.svg`}
                      alt="cancel icon"
                      style={{ width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Nein</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "-0.9vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Ja</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "rgba(173, 216, 230, 0.4)" }}>
                    <img
                      src={`${basicUri}/cancel.svg`}
                      alt="ok icon"
                      style={{ width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Nein</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "3vh" }}>
                    jaa
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(128, 0, 128, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/unklar.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "0.8vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Unklar</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "rgba(173, 216, 230, 0.4)" }}>
                    <img
                      src={`${basicUri}/cancel.svg`}
                      alt="ok icon"
                      style={{ width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw", lineHeight: "2.5vh" }}>
                      Nein
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "2.5vh" }}>
                    jaa
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "-0.9vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Ja</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/cancel.svg`}
                      alt="cancel icon"
                      style={{ width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Nein</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "-0.9vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Ja</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/cancel.svg`}
                      alt="cancel icon"
                      style={{ width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Nein</span>
                  </td>
                </tr>
                <tr
                  style={{
                    lineHeight: "2.5vh",
                  }}
                >
                  <td style={{ color: "transparent" }}>jaa</td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(255, 20, 147, 1)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "-0.7vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Ja</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(255, 20, 147, 1)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/cancel.svg`}
                      alt="cancel icon"
                      style={{ marginRight: "0.3vw", width: "0.8vw" }}
                    />
                    Nein
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginLeft: "-0.9vw", width: "0.8vw" }}
                    />
                    <span style={{ marginLeft: "0.3vw" }}>Ja</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-lie">
            <table className="table3">
              {" "}
              {/* Dritte Tabelle (25vw) */}
              <thead>
                <tr className="column-header">
                  <th>Private KI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "2.5vh" }}>
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(52, 104, 204, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "2.5vh" }}>
                    {" "}
                    jaa
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(128, 0, 128, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "2.5vh" }}>
                    {" "}
                    jaa12
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(75, 0, 130, 0.8)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td style={{ color: "transparent", lineHeight: "2.5vh" }}>
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(255, 20, 147, 1)",
                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottom: "2px solid rgba(255, 20, 147, 1)",

                      lineHeight: "2.5vh",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      borderBottomRightRadius: "1vw",
                      lineHeight: "2.5vhkamilo",
                    }}
                  >
                    <img
                      src={`${basicUri}/ok.svg`}
                      alt="ok icon"
                      style={{ marginRight: "0.5vw", width: "0.8vw" }}
                    />
                    Ja
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCard;
