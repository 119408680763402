import React from "react";
// import "./cloudengineering.css";
import { useNavigate } from "react-router-dom";

const HpcPage = () => {
  const navigate = useNavigate();
  return (
    <div className="kicontainer">
      <div className="videosection">
        <img
          className="videocard"
          src={`${process.env.PUBLIC_URL}/images/hpc_initial.png`}
          style={{ borderRadius: "3vh" }}
          alt="Carsten Halmen"
        />
      </div>
      <div className="header-newsection-container">
        <div className="headercard">
          <h1 className="heading-ki">
            Mehr Geschwindigkeit. Mehr Erfolg! Mit der Kraft von <br />
            <span className="highlight">High-Performance-Computing</span>
          </h1>
          <li>Funktionserweiterung von bestehenden Softwarelösungen</li>
          <li>Reduktion von Ressourcenbedarf (Cloudkosten)</li>
          <li>
            Erreichen von Akzeptanzkriterien für erweiterte Kundenanforderungen
          </li>
          <button
            className="readmore-button"
            onClick={() => {
              const element = document.getElementById("solutions");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Mehr erfahren
            <div className="dropdown-icon">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/dropdownicon.svg`}
              />
            </div>
          </button>
        </div>
        <div className="newsection" id="solutions">
          Für Software-Projektleiter, die die Grenzen der Systemperformance
          überwinden möchten.
        </div>
      </div>
      <div className="solutions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "1fr 4fr" }}
          >
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/computer.svg`}
                alt="location icon"
              />
            </div>
            <div>
              <h3>Hardware-Optimierung durch Code-Anpassung</h3>
              <p>
                Durch die Anpassung Ihrer Software an hardwarenahe
                Programmiersprachen können wir die Leistungsfähigkeit Ihres
                Codes verbessern und die Ausführungszeit reduzieren. Unsere
                Experten helfen Ihnen dabei, Ihre bestehenden Lösungen zu
                optimieren.
              </p>
            </div>
          </div>
          <div
            className="solutionCard"
            style={{
              gridTemplateColumns: "4fr 1fr",
              marginLeft: "40vw",
            }}
          >
            <div>
              <h3>Skalierbare Performance durch Parallelisierung</h3>
              <p>
                Durch die skalierte Parallelisierung können wir Ihre Anwendungen
                auf mehrere Prozessoren und Kerne ausführen, um die
                Leistungsfähigkeit zu erhöhen. Unsere Experten helfen Ihnen
                dabei, Ihre Anwendungen für parallele Ausführung vorzubereiten.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/multiarrow.svg`}
                alt="protection icon"
              />
            </div>
            {/* </div> */}
          </div>
          {/* <div className="solutionright"> */}
          <div
            className="solutionCard"
            style={{ gridTemplateColumns: "4fr 1fr" }}
          >
            <div>
              <h3>Leistungsfähigkeit durch Hardware-Spezialisierung</h3>
              <p>
                Durch den Einsatz von anwendungsoptimierten Hardwaresettings wie
                GPU-Technologien können wir die Leistungsfähigkeit Ihrer
                Anwendungen verbessern. Unsere Experten helfen Ihnen dabei, die
                richtigen Hardware-Komponenten für Ihre spezifischen
                Anforderungen auszuwählen.
              </p>
            </div>
            <div className="iconholder">
              <img
                style={{ width: "1.5vw" }}
                src={`${process.env.PUBLIC_URL}/icons/chip.svg`}
                alt="frame icon"
              />
            </div>
          </div>
          <div className="solution-central" style={{ display: "none" }}>
            <div className="solution-little-card">
              Sie haben rechtliche Bedenken oder Fragen zur Nutzung von KI?
              Unser Systempartner DDSK ist spezialisiert und hilft Ihnen gerne
              weiter.
              <div>
                <button
                  onClick={() =>
                    window.open("https://www.ddsk.gmbh/ai", "_blank")
                  }
                  style={{
                    marginTop: "0vh",
                    marginLeft: "3vw",
                    width: "12vw",
                    padding: "1vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="readmore-button"
                >
                  Die DDSK kennenlernen{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="process"
        style={{ backgroundColor: "white", width: "100%", height: "100vh" }}
      >
        <div className="text">Der Prozess, der Sie erwartet</div>
        <div>
          <img
            style={{ width: "60vw", height: "90vh" }}
            src={`${process.env.PUBLIC_URL}/images/hpc-process-img.svg`}
            alt="process"
          />
        </div>
      </div>
      <div className="more">
        <p>Weitere Leisungen</p>
        <div className="more-container">
          <div
            onClick={() => {
              navigate("/software-development");
              window.location.reload();
            }}
          >
            Software-
            <br />
            entwicklung
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/pc-code.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/cloud-engineering");
              window.location.reload();
            }}
          >
            Cloud <br /> Engineering
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/cloud.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/iot");
              window.location.reload();
            }}
          >
            Internet of <br /> things
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/iot.svg`}
              alt="PC-Icon"
            />
          </div>
          <div
            onClick={() => {
              navigate("/künstliche-intelligenz");
              window.location.reload();
            }}
          >
            Künstliche <br /> Intelligenz
            <img
              style={{ width: "2vw", height: "5vh" }}
              src={`${process.env.PUBLIC_URL}/icons/ki.svg`}
              alt="KI-Icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HpcPage;
