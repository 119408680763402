import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-container">
      <div className="ci-logo-area">
        <img
          width={300}
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
          src={`${process.env.PUBLIC_URL}/icons/ci-dev.svg`}
          alt="ci-dev-logo"
        />
      </div>
      <div className="footer">
        <span onClick={() => navigate("/datenschutz")}>Datenschutz</span>
        <span onClick={() => navigate("/impressum")}>Impressum</span>
        <img
          onClick={() =>
            window.open("https://www.linkedin.com/company/ci-dev/", "_blank")
          }
          src={`${process.env.PUBLIC_URL}/icons/linkedin.svg`}
          width={40}
          alt="linkedin-icon"
        />
      </div>
    </div>
  );
};

export default Footer;
