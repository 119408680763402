import React from "react";
import "./navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const iconUrl = `${process.env.PUBLIC_URL}/icons`;
  return (
    <div className="nav-container">
      <div className="nav-content">
        <button
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          <img
            width={"70%"}
            src={`${iconUrl}/ki.svg`}
            title="Künstliche Intelligenz"
            alt="KI-Icon"
          />
        </button>
        <button
          onClick={() => {
            navigate("/cloud-engineering");
            window.location.reload();
          }}
        >
          <img
            width={"70%"}
            src={`${iconUrl}/cloud.svg`}
            title="Cloud-Engineering"
            alt="Cloud-Icon"
          />
        </button>
        <button
          onClick={() => {
            navigate("/iot");
            window.location.reload();
          }}
        >
          <img
            width={"70%"}
            src={`${iconUrl}/iot.svg`}
            title="Internet of Things"
            alt="IOT-Icon"
          />
        </button>
        <button
          onClick={() => {
            navigate("/software-development");
            window.location.reload();
          }}
        >
          <img
            width={"70%"}
            src={`${iconUrl}/pc-code.svg`}
            title="Softwareentwicklung"
            alt="PC-Icon"
          />
        </button>
        <button
          onClick={() => {
            navigate("/hpc");
            window.location.reload();
          }}
        >
          <img
            width={"70%"}
            src={`${iconUrl}/thunder.svg`}
            title="High Performance Computing"
            alt="Thunder-Icon"
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
