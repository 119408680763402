import React, { useState, useEffect } from "react";
import "./header.css";

const NewHeader = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scrolling ? "scrolled" : ""}`}>
      <div className={`background-blur ${scrolling ? "blurred" : ""}`} />

      <div className="logo" onClick={() => (window.location.href = "/")}>
        <img src={`${process.env.PUBLIC_URL}/icons/ci-dev.svg`} alt="Logo" />
      </div>
      <div style={{ paddingRight: "2vw" }}>
        <button
          className="cta-btn"
          onClick={() =>
            window.open(
              "https://calendly.com/ci-dev/kickoff-ci-development?month=2024-09",
              "_blank"
            )
          }
        >
          Kostenfreie Beratung sichern
        </button>
      </div>
    </header>
  );
};

export default NewHeader;
